.sales__orders__order {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
    margin: 16px 0;
}
.sales__orders__order__price {
    justify-self: flex-end;
    color: var(--lime);
    font-weight: 900;
    font-size: 18px;
}
.sales__heading {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.sales__heading--shrink {
    letter-spacing: 0;
    font-weight: 900;
    color: var(--lime);
}
.sales__heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
